// Here you can add other styles
.previewImage {
  max-height: 300px;
  max-width: 100%;
}

.tableImage {
  max-height: 100px;
  max-width: 100px;
}

.specialistImage {
  width: 300px;
}

.specialistCards {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  & > div {
    margin-right: 20px;
  }
}

.scheduleDay {
  margin-bottom: 20px;
}

.scheduleDayHeader {
  text-transform: capitalize;
}

.slotBtn {
  margin: 0 5px 5px 0;
}

.messages-view {
  // 104 - header, 32 - main padding-top, - custom
  height: calc(100vh - 104px - 32px - 96px);
  display: flex;
  flex-direction: column;
  justify-content: start;

  &__card {
    width: 100%;
    height: calc(100% - 50px); // 50px - title height
    flex: 1;
  }

  &__card-body {
    height: calc(100% - 40px);
  }

  &--mobile {
    height: calc(100vh - 104px - 32px - 96px);
  }
}

.mobile-chat {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: calc(100vh - calc(100vh - 100%));
  // height: -moz-available;
  height: -webkit-fill-available;
  height: fill-available;
  background: #fff;
  z-index: 9999;
  padding: 0;
  margin: 0;
  overflow: hidden;
}

.chat {
  $this: &;
  display: flex;
  height: 100%;

  &__list {
    overflow-y: auto;
    max-height: 100%;
  }

  &__messages-wrapper {
    height: 100%;
  }

  &--mobile {
  }
}

.chat-online {
  color: #34ce57;
}

.chat-offline {
  color: #e4606d;
}

.chat-messages {
  display: flex;
  flex-direction: column;

  overflow-y: scroll;
  height: calc(
    100% - 56px - 64px
  ); // 100% - input - <main> padding-top - chat-header

  // xl 1200
  @media screen and (max-width: 1199px) {
    height: calc(100% - 56px - 64px); // height - companion name height
  }

  // lg 992
  @media screen and (max-width: 991px) {
    height: calc(100% - 56px - 66px);
  }

  &--mobile {
    // 100% - input - chat-header
    $chat-messages-height--mobile: calc(100% - 52px - 53px);
    height: $chat-messages-height--mobile;

    // xl 1200
    @media screen and (max-width: 1199px) {
      height: $chat-messages-height--mobile;
    }

    // lg 992
    @media screen and (max-width: 991px) {
      height: $chat-messages-height--mobile;
    }
  }
}

.chat-message-left,
.chat-message-right {
  display: flex;
  flex-shrink: 0;
}

.chat-message-left {
  margin-right: auto;
}

.chat-message-right {
  flex-direction: row-reverse;
  margin-left: auto;
}
.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}
.px-4 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}
.flex-grow-0 {
  flex-grow: 0 !important;
}
.border-top {
  border-top: 1px solid #dee2e6 !important;
}

.cursor-pointer {
  cursor: pointer;
}

.star-rating {
  &__stars {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  &__star {
    all: unset;

    position: relative;
    outline: none !important;
    width: 2.5rem;
    height: 2.5rem;

    & > svg {
      position: absolute;
      inset: 0;
      margin: auto;
      width: 100% !important;
      height: 100% !important;
      font-size: 100% !important;
      color: #fc0 !important;
    }
  }

  &__star-icon-solid {
    opacity: 0;

    &_state_active {
      opacity: 1;
    }
  }
}

.activeDay {
  background: #1b9e3e;
}

.hasSlots {
  background: #555;
  color: #fff;
}

.hasFreeSlots {
  background: #1b9e3e;
  color: #fff;
}

.btn-telegram {
  background-color: #229ED9;
  color: #fff;
}

.btn-whatsapp {
  background-color: #25D366;
  color: #fff;
}

.sidebar-link {
  color: #fff;
  &:hover {
    color: #fff;
  }
}
